/* global VXConfig */
import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';
import ReloadHelper from '../../utils/ReloadHelper';


const MIN_SEARCH_PATTERN_LENGTH = 2;

class TextSearch extends React.Component {

	static getLink(link, newTag) {
		const newParams = link.substring(1).split('&').filter((val) => !val.startsWith('searchPattern=') && !val.startsWith('page='));
		newParams.push('searchPattern=' + newTag);
		newParams.push('page=1'); // On search jump to first page
		return window.location.pathname + '?' + newParams.join('&');
	}

	constructor(props) {
		super(props);

		this.state            = {
			searchText: this.props.searchText,
			showClearBtn: !!this.props.searchText,
			showSearchField: !!this.props.searchText,
		};
		this.inputField       = null;
		this.ignoreFocusEvent = false;
		this.onKeyPress       = this.onKeyPress.bind(this);
		this.clear            = this.clear.bind(this);
		this.doSearch         = this.doSearch.bind(this);
		this.onInputChange    = this.onInputChange.bind(this);
		this.focusInput       = this.focusInput.bind(this);
	}

	doSearch() {
		if (typeof this.props.doSearch === 'function') {
			this.props.doSearch(this.state.searchText);
		} else {
			const baselink = window.location.search;
			const newlink  = TextSearch.getLink(baselink, this.state.searchText);
			if (newlink) {
				ReloadHelper.reload(newlink);
			}
		}
	}

	onKeyPress(e) {
		if (this.props.searchEmpty ? this.state.searchText.length < this.props.searchPatternLength && this.state.searchText.length > 0 : this.state.searchText.length < this.props.searchPatternLength) {
			return;
		}

		switch (e.keyCode) {
			case 13:
				this.doSearch();
				break;
			default:
				break;
		}
	}

	onInputChange(e) {
		e.preventDefault();
		e.stopPropagation();
		const searchPattern = e.target.value;
		this.setSearchPattern(searchPattern);
	}

	setSearchPattern(searchPattern) {
		const keyPattern   = /^[a-z0-9äöüÄÖÜß -]*$/i; // @see ArgumentFilter
		const isFirstSpace = searchPattern.length > 0 && !searchPattern.replace(/\s/g, '').length;

		if (!keyPattern.test(searchPattern) || isFirstSpace) {
			return;
		}

		this.setState({
			searchText: searchPattern, showClearBtn: true,
		});
	}

	clear() {
		if (this.props.isMyVisitX && !this.inputField.value) {
			this.setState({
				showSearchField: false, showClearBtn: false,

			});
		} else {
			this.inputField.value = '';
			this.setState({
				searchText: '', showClearBtn: false,
			}, () => this.doSearch());
		}
	}

	focusInput() {
		this.inputField.classList.add('-is-expanded');
		this.inputField.focus();

		this.setState({
			showSearchField: true,
		});
	}

	render() {
		const InputField = (className) => (<input type="search"
		                                          className={className}
		                                          placeholder={Translations.get('Search')}
		                                          onKeyDown={this.onKeyPress}
		                                          onInput={this.onInputChange}
		                                          autoComplete="off"
		                                          ref={ref => this.inputField = ref}
		                                          defaultValue={this.state.searchText}
			/>);

		if (this.props.isMyVisitX) {
			let groupClassName = 'mediapanel__filter-inputgroup';
			if (this.state.isFocused) {
				groupClassName += ' -focused';
			}

			return <div className="tag-search">
				<div className={groupClassName}>
					<span className="icon -icon-search-full h-cursor-pointer"
					      onClick={this.state.showSearchField ? this.doSearch : this.focusInput} />
					{InputField("mediapanel__filter-searchform" + (this.state.showSearchField ? ' -is-expanded' : ''))}

					{this.state.showSearchField || (VXConfig.device.isTablet && this.state.showClearBtn) ?
						<span className="tag-search__clear icon -icon-close-full h-cursor-pointer" onClick={this.clear} /> : ''}
				</div>
			</div>;
		}

		return (<div className={"profile-videos-block--header-controls-tags" + (this.props.lightTheme ? ' profile-videos-block--header-controls-tags-light' : '')}>
				<div className="profile-videos-block--header-controls-tags-row">
					<span className={'profile-videos-block--header-controls-tags-icon icon -icon-search-full h-cursor-pointer'}
					      onClick={this.doSearch} />
					<div className="profile-videos-block--header-controls-tags-input-wrapper">
						{InputField("profile-videos-block--header-controls-tags-input")}
					</div>
					{(this.state.showClearBtn) &&
						<div onClick={this.clear} className={"profile-videos-block--header-controls-tags-icon -margin-right h-cursor-pointer"}>
							<i className={"icon -icon-round-close"}></i>
						</div>}
				</div>
			</div>);
	}
}

TextSearch.propTypes = {
	doSearch:            PropTypes.func,
	searchText:          PropTypes.string,
	isMobile:            PropTypes.bool,
	isMyVisitX:          PropTypes.bool,
	searchPatternLength: PropTypes.number,
	searchEmpty:         PropTypes.bool,
	lightTheme:          PropTypes.bool,
};

TextSearch.defaultProps = {
	searchText: '',
	isMobile: false,
	isMyVisitX: true,
	searchPatternLength: MIN_SEARCH_PATTERN_LENGTH,
	searchEmpty: false,
	lightTheme: false,
};

export default TextSearch;
